<template>
  <div class="flex mobile:flex-col">
    <div class="w-3/12 mobile:w-full flex">
      <span class="hidden mobile:flex">Nimi: </span>{{ data.name }}
    </div>
    <div class="w-3/12 mobile:w-full flex">
      <span class="hidden mobile:flex">Haldaja: </span>{{ data.provider }}
    </div>
    <div class="w-4/12 mobile:w-full flex">
      <span class="hidden mobile:flex">Eesmärk: </span>{{ data.purpose }}
    </div>
    <div class="w-1/12 mobile:w-full flex">
      <span class="hidden mobile:flex">Kestvus: </span>{{ data.expiry }}
    </div>
    <div class="w-1/12 mobile:w-full flex">
      <span class="hidden mobile:flex">Tüüp: </span>{{ data.type }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
