<template>
  <div class="wrapper">
    <div class="container">
      <div class="flex py-8 flex-col w-full mx-auto mobile: px-2">
        <h2>Küpsiste kasutamine</h2>
        <p>
          See veebileht kasutab küpsiseid. Küpsiste kasutamise eesmärk on parima
          kasutajakogemuse tagamine.
        </p>
        <h2>Info küpsiste kohta mida kasutame</h2>
        <h3>Vajalikud</h3>
        <p>Need küpsised on vajalikud veebilehe normaalse töö talitamiseks</p>
        <div class="flex flex-col bg-white rounded-md p-3 mb-2">
          <div class="flex font-semibold mobile:hidden">
            <div class="w-3/12">Nimi</div>
            <div class="w-3/12">Haldaja</div>
            <div class="w-4/12">Eesmärk</div>
            <div class="w-1/12">Kestvus</div>
            <div class="w-1/12">Tüüp</div>
          </div>
          <cookie-declaration-row
            v-for="item in nescessary"
            :key="item.name"
            :data="item"
          ></cookie-declaration-row>
        </div>
        <h3>Statistika</h3>
        <p>
          Neid küpsiseid kasutatakse, et koguda anonüümset kasutajainfot
          veebilehe kasutajakogemuse parandamiseks.
        </p>
        <div class="flex flex-col bg-white rounded-md p-3">
          <div class="flex font-semibold mobile:hidden">
            <div class="w-3/12">Nimi</div>
            <div class="w-3/12">Haldaja</div>
            <div class="w-4/12">Eesmärk</div>
            <div class="w-1/12">Kestvus</div>
            <div class="w-1/12">Tüüp</div>
          </div>
          <cookie-declaration-row
            v-for="item in stats"
            :key="item.name"
            :data="item"
          ></cookie-declaration-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CookieDeclarationRow from "@/components/reusable/CookieDeclarationRow";
export default {
  components: {
    CookieDeclarationRow
  },
  data() {
    return {
      nescessary: [
        {
          name: "gwd_coreCookies",
          provider: "gewodo.com",
          purpose:
            "Veebilehe baasfunktsioonideks vajalike protsesside talitamine",
          expiry: "Püsiv",
          type: "HTML küpsis"
        },
        {
          name: "gwd_consent_id",
          provider: "gewodo.com",
          purpose: "Küpsistevalikute tegemise tõendamine"
        },
        {
          name: "gwd_consent_timeset",
          provider: "gewodo.com",
          purpose: "Küpsistevalikute tegemise tõendamine"
        },
        {
          name: "gwd_activationPopup",
          provider: "gewodo.com",
          purpose: "Märkida aktivatsiooni meeldetuletust vaadatuks",
          expiry: "1 päev",
          type: "HTML küpsis"
        },
        {
          name: "gwd_feedbackBanner",
          provider: "gewodo.com",
          purpose: "Märkida tagasiside ülekandmise teatist vaadatuks",
          expiry: "Püsiv",
          type: "HTML küpsis"
        }
      ],
      stats: [
        {
          name: "_ga",
          provider: "Google Tag Manager",
          purpose:
            "Statistiliste andmete genereerimine Google Analytics teenuse jaoks",
          expiry: "2 aastat",
          type: "HTTP küpsis"
        },
        {
          name: "wd",
          provider: "Facebook",
          purpose: "Resolutsioonistatistika kogumine",
          expiry: "1 nädal",
          type: "HTTP küpsis"
        },
        {
          name: "presence",
          provider: "Facebook",
          purpose: "Jälgida brauseri vahelehe aktiivsust",
          expiry: "1 kuu",
          type: "HTTP küpsis"
        },
        {
          name: "sb",
          provider: "Facebook",
          purpose: "Brauseri tuvastamine",
          expiry: "2 aastat",
          type: "HTTP küpsis"
        }
      ],
      marketing: [
        {
          name: "fr",
          provider: "Facebook",
          purpose: "Võimaldada reklaamide tootluse jälgimist",
          expiry: "3 kuud",
          type: "HTTP Küpsis"
        },
        {
          name: "datr",
          provider: "Facebook",
          purpose: "Pettusevastase funktsionaalsuse toetamine",
          expiry: "2 aastat",
          type: "HTTP küpsis"
        },
        {
          name: "xs",
          provider: "Facebook",
          purpose: "Facebooki sessiooni tuvastamine",
          expiry: "3 kuud",
          type: "HTTP küpsis"
        }
      ]
    };
  }
};
</script>
